import {
  GET_COLLECTIONS,
  SET_COLLECTIONS,
  GET_COLLECTIONS_FAILED,
  UPDATE_COLLECTIONS_VISIBLE,
  SET_DRAWER_VISIBLE,
  CREATE_COLLECTION,
  CREATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION,
  RESET_FIELD,
  UPLOAD_IMAGE,
  SET_UPLOAD_IMAGE,
  SET_COLLECTIONS_STATE
} from '../actions';

export const getCollections = () => ({
  type: GET_COLLECTIONS,
});

export const setCollections = (data) => ({
  type: SET_COLLECTIONS,
  payload: data,
});

export const getCollectionsFailed = (data) => ({
  type: GET_COLLECTIONS_FAILED,
  payload: data,
});

export const updateCollectionVisible = (data) => ({
  type: UPDATE_COLLECTIONS_VISIBLE,
  payload: data
})

export const setState = (data) => ({
  type: SET_COLLECTIONS_STATE,
  payload: data
})

export const setDrawerVisible = (data) => ({
  type: SET_DRAWER_VISIBLE,
  payload: data,
});

export const uploadImage = (data) => ({
  type: UPLOAD_IMAGE,
  payload: data,
});

export const setUploadImage = (data) => ({
    type: SET_UPLOAD_IMAGE,
    payload: data
})

export const createCollectionDetials = (data) => ({
  type: CREATE_COLLECTION,
  payload: data,
});

export const updateCollection = (data) => ({
  type: UPDATE_COLLECTION,
  payload: data,
});

export const resetField = () => ({
  type: RESET_FIELD,
  // payload: data
});

export const createCollectionStatus = (data) => ({
  type: CREATE_COLLECTION_SUCCESS,
  payload: data,
});
