import { all, call, delay, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  GET_PREMINT_ASSEST,
  GET_PREMINT_ASSEST_COUNT,
  CREATE_PREMINT_ASSEST,
  UPDATE_PREMINT_ASSEST,
  UPDATE_PREMINT_ASSEST_SHOW_AND_HIDE,
  UPLOAD_PREMINT_IMAGE,
} from '../actions';

import {
  setPremintDetails,
  setPremintCountDetails,
  setCreatePremintAssest,
  setLoadingCreatePremintAssest,
  createAssestFailed,
  clearField,
  setState,
  clearState,
  setUploadPreiminImage
} from './aciotns';

import {
  getPremintAssest,
  getPremintAssestCount,
  createPremintAssest,
  updatePremintAssest,
  updateShowHidePremintAssetAPI,
  uploadPremintAssestImageAPI
} from './getAssests';

export function* getTableData(action) {
  try {
    yield put(setPremintDetails({ data: [], isLoading: true }));
    const response = yield call(getPremintAssest, action.payload);
    const { data } = response;
    yield put(setPremintDetails({ data: data.data, isLoading: false, }));
    yield put(clearField())
  } catch (error) {
    yield put(setPremintDetails({ data: [], isLoading: false }));
    // console.log(error);
  }
}

export function* getAssetCountData() {
  try {
    const response = yield call(getPremintAssestCount);
    const { data } = response;
    yield put(setPremintCountDetails(data.count));
  } catch (error) {
    //   yield put(getSurveyDetailError(error));
    console.log(error);
  }
}

export function* uploadPremintImageData(action) {
  console.log(action)
  try {
    yield put(setUploadPreiminImage({image:null, imgLoading: true}))
    const response = yield call(uploadPremintAssestImageAPI, action.payload);
    // console.log(response.data.message.hash);
    yield put(setUploadPreiminImage({image:response?.data?.message?.hash, imgLoading: false}))
  } catch (error) {
    yield put(setUploadPreiminImage({image:null, imgLoading: false}))
  }
}

export function* createAssest(action) {
  try {
    yield put(
      setCreatePremintAssest({ status: null, isLoading: true, error: null })
    );
    yield call(createPremintAssest, action.payload);
    yield put(setCreatePremintAssest({ status: 'success', isLoading: false }));
    yield put(clearField());
  } catch (error) {
    if (
      error.response.data.error ===
      'collectionName Already Exist Please Provide Different collectionName. In Case You have Not Provided collectionName Then Provide Different Asset Name'
    ) {
      yield put(
        createAssestFailed({ error: 'Collection Name Already Exist!' })
      );
    }else{
      yield put(
        createAssestFailed({ error: 'error' })
      );
    }
    yield put(clearField());
  }
}

export function* updateAssest(action) {
  try {
    yield put(
      setCreatePremintAssest({ status: null, isLoading: true, error: null })
    );
    yield call(updatePremintAssest, action.payload);
    yield put(setCreatePremintAssest({ status: 'success', isLoading: false }));
    yield put(clearField());
  } catch (error) {
    if (
      error.response.data.error ===
      'collectionName Already Exist Please Provide Different collectionName. In Case You have Not Provided collectionName Then Provide Different Asset Name'
    ) {
      yield put(
        createAssestFailed({ error: 'Collection Name Already Exist!' })
      );
    } else {
      yield put(createAssestFailed({ error: 'error' }));
    }
    yield put(clearField());
  }
}

export function* updatePremintAssetShowHide(action) {
  try {
    yield put(setState({error: null, status: null, isLoading:true}))
    yield call(updateShowHidePremintAssetAPI, action.payload);
    yield put(setState({status: 'success', isLoading: false, error: null }))
    yield put(setState({status: null, isLoading: false, error: null }))
  } catch (error) {
    yield put(setState({status: 'failed', isLoading: true, error: error.response }))
  }
}

export function* watchGetAssestsItem() {
  yield takeEvery(GET_PREMINT_ASSEST, getTableData);
}

export function* watchGetAssestsCount() {
  yield takeEvery(GET_PREMINT_ASSEST_COUNT, getAssetCountData);
}

export function* watchuploadPremintImageData() {
  yield takeEvery(UPLOAD_PREMINT_IMAGE, uploadPremintImageData);
}

export function* watchCreateAssest() {
  yield takeEvery(CREATE_PREMINT_ASSEST, createAssest);
}
export function* watchUpdateAssest() {
  yield takeEvery(UPDATE_PREMINT_ASSEST, updateAssest);
}
export function* watchUpdateAssestShowAndHide() {
  yield takeEvery(
    UPDATE_PREMINT_ASSEST_SHOW_AND_HIDE,
    updatePremintAssetShowHide
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAssestsItem),
    fork(watchGetAssestsCount),
    fork(watchuploadPremintImageData),
    fork(watchCreateAssest),
    fork(watchUpdateAssest),
    fork(watchUpdateAssestShowAndHide),
  ]);
}
