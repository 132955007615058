
//For Production
// var apiBaseUrl = 'https://backend.drops.thenftbrewery.com';
var apiBaseUrl = 'https://backend.drops.datacurve.io';



//For Development


export function getAPiUrl() {
  return apiBaseUrl;
}

// export function getsmartcontracturl(){
//   return smartcontracturl;
// }
