import {
  GET_PREMINT_ASSEST,
  CREATE_PREMINT_ASSEST,
  UPDATE_PREMINT_ASSEST,
  SET_CREATE_PREMINT_ASSEST,
  UPDATE_COLLECTION,
  SET_PREMINT_ASSEST,
  GET_PREMINT_ASSEST_COUNT,
  SET_PREMINT_ASSEST_COUNT,
  SET_LOADING_CREATE_PREMINT_ASSEST,
  CREATE_ASSEST_FAILED,
  CLEAR_FIELD,
  UPDATE_PREMINT_ASSEST_SHOW_AND_HIDE,
  SET_STATE,
  CLEAR_STATE,
  UPLOAD_PREMINT_IMAGE,
  SET_UPLOAD_PREMINT_IMAGE
} from '../actions';

export const getPremintDetails = (id) => ({
  type: GET_PREMINT_ASSEST,
  payload: id
});

export const uploadPreimintImage = (data) => ({
  type: UPLOAD_PREMINT_IMAGE,
  payload: data,
});
export const setUploadPreiminImage = (data) => ({
  type: SET_UPLOAD_PREMINT_IMAGE,
  payload: data
})

export const setPremintDetails = (data) => ({
  type: SET_PREMINT_ASSEST,
  payload: data,
});

export const createPremintAssestDetials = (data) => ({
  type: CREATE_PREMINT_ASSEST,
  payload: data,
});
export const updatePremintAssestDetials = (data) => ({
  type: UPDATE_PREMINT_ASSEST,
  payload: data,
});

export const updatePremitnAssetShowAndHide = (data) => ({
  type: UPDATE_PREMINT_ASSEST_SHOW_AND_HIDE,
  payload: data
})

export const setState = (data) => ({
  type: SET_STATE,
  payload: data
})
export const clearState = (data) => ({
  type: CLEAR_STATE,
  // payload: data
})

export const clearField = () => ({
  type: CLEAR_FIELD
});

export const createAssestFailed = (error) => ({
  type: CREATE_ASSEST_FAILED,
  payload: error
});

export const updateCollection = (data) => ({
  type: UPDATE_COLLECTION,
  payload: data
})

export const getPremintCountDetails = () => ({
  type: GET_PREMINT_ASSEST_COUNT,
  // payload:data
});

export const setPremintCountDetails = (data) => ({
  type: SET_PREMINT_ASSEST_COUNT,
  payload: data,
});


export const setCreatePremintAssest = (data) => ({
  type: SET_CREATE_PREMINT_ASSEST,
  payload: data
});

export const setLoadingCreatePremintAssest = () => ({
  type: SET_LOADING_CREATE_PREMINT_ASSEST,
  // payload:data
});


