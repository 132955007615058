import {
  SET_COLLECTIONS,
  GET_COLLECTIONS_FAILED,
  SET_DRAWER_VISIBLE,
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_FAILED,
  RESET_FIELD,
  SET_UPLOAD_IMAGE,
  SET_COLLECTIONS_STATE,
} from '../actions';

const initialState = {
  isLoading: true,
  collections: [],
  error: false,
  drawerVisible: false,
  status: null,
  type: '',
  collectionUpdateData: {},
  image: null,
  imgLoading: false,
  message: ''
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_COLLECTIONS:
      return { ...state, collections: payload, isLoading: false, };
    case GET_COLLECTIONS_FAILED:
      return { ...state, isLoading: false, error: true };
    case SET_DRAWER_VISIBLE:
      // console.log(payload);
      return {
        ...state,
        drawerVisible: payload.visible,
        type: payload.type,
        collectionUpdateData: payload,
        image: null
      };
    // case UPLOAD_IMAGE:
    //   return {...state, image: action.payload }
    case SET_COLLECTIONS_STATE:
      // console.log(action)
      return { ...state, error: payload.error, isLoading: payload.isLoading, status: payload.status };
    case SET_UPLOAD_IMAGE:
      return {
        ...state,
        image: action.payload.image,
        imgLoading: action.payload.imgLoading,
      };
    case CREATE_COLLECTION_SUCCESS:
      return { ...state, isLoading: payload.isLoading, status: payload.status, message: payload.message };
    case CREATE_COLLECTION_FAILED:
      return { ...state, isLoading: payload.isLoading, status: payload.status };
    case RESET_FIELD:
      // console.log(payload)
      return { ...state, error: null, status: '', isLoading: false };
    default:
      return { ...state };
  }
}
