import Axios from 'axios';
const Apibaseurl = require('../../constants/apibaseurl');
var apibaseurl = Apibaseurl.getAPiUrl();

export const getCollectionData = () => {
  const config = {
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
  return Axios.get(`${apibaseurl}/api/admin/allCollection`, config);
};

export const CollectionApi = (data) => {
  const config = {
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
  return Axios.post(`${apibaseurl}/api/admin/createCollection`, data, config);
};

export const updateVisibleCollectionApi = (data) => {
  const config = {
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
  return Axios.post(`${apibaseurl}/api/admin/updateCollectionPublishItem`, data, config);
};

export const updateCollectionDetails = (data) => {
  const config = {
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
  return Axios.post(`${apibaseurl}/api/admin/updateCollection`, data, config);
};

export const uploadCollectionImageAPI = (data) => {
  const config = {
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
console.log(data)
  return Axios.post(
    `${apibaseurl}/api/admin/uploadCollectionImage`,
    data,
    config
  );
  // https://backend.daniclosandorra.thenftbrewery.com/api/admin/uploadCollectionImage
};
