import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
//  import todoSagas from './todo/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';
import premintSaga from "./PremintAssests/saga"
import collectionSaga from "./collection/saga"
// import GetAssestByCollectionId from "./collectionPremintAssest/saga"

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    //  todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
    premintSaga(),
    collectionSaga(),
    // GetAssestByCollectionId()
  ]);
}
