import {
  SET_CREATE_PREMINT_ASSEST,
  SET_PREMINT_ASSEST,
  SET_PREMINT_ASSEST_COUNT,
  SET_LOADING_CREATE_PREMINT_ASSEST,
  CREATE_ASSEST_FAILED,
  CLEAR_FIELD,
  SET_STATE,
  CLEAR_STATE,
  SET_UPLOAD_PREMINT_IMAGE,
} from '../actions';

const initialState = {
  isLoading: true,
  premintAssests: [],
  assestCount: '',
  status: null,
  error: null,
  image: null,
  imgLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PREMINT_ASSEST:
      return {
        ...state,
        premintAssests: payload.data,
        isLoading: payload.isLoading,
        // status: payload.status,
      };
    case SET_PREMINT_ASSEST_COUNT:
      return { ...state, assestCount: payload };
    case SET_LOADING_CREATE_PREMINT_ASSEST:
      return { ...state, isLoading: true };
    case SET_UPLOAD_PREMINT_IMAGE:
      return {
        ...state,
        image: action.payload.image,
        imgLoading: action.payload.imgLoading,
      };
    case SET_CREATE_PREMINT_ASSEST:
      return {
        ...state,
        status: payload.status,
        isLoading: payload.isLoading,
        error: payload.error,
        image: null
      };
    case SET_STATE:
      // console.log(action)
      return {
        ...state,
        status: payload.status,
        isLoading: payload.isLoading,
        error: payload.error,
      };
    // return { ...state, status: 'success' };
    case CREATE_ASSEST_FAILED:
      console.log(payload)
      return {
        ...state,
        error: payload.error,
        status: 'failed',
        isLoading: false,
      };
    case CLEAR_FIELD:
    case CLEAR_STATE:
      return { ...state, status: null, error: null };
    default:
      return { ...state };
  }
}
