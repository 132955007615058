import { call, takeEvery, all, fork, put } from 'redux-saga/effects';

import {
  CREATE_COLLECTION,
  GET_COLLECTIONS,
  UPDATE_COLLECTION,
  UPLOAD_IMAGE,
  UPDATE_COLLECTIONS_VISIBLE
} from '../actions';

import {
  setCollections,
  getCollectionsFailed,
  createCollectionStatus,
  setUploadImage,
  setState
} from './action';

import {
  getCollectionData,
  CollectionApi,
  updateCollectionDetails,
  uploadCollectionImageAPI,
  updateVisibleCollectionApi
} from './getCollection';

export function* getCollectionTableData() {
  try {
    const res = yield call(getCollectionData);
    // console.log(res);
    yield put(setCollections(res.data.data));
  } catch (error) {
    // console.log(error);
    yield put(getCollectionsFailed(error));
  }
}

export function* uploadImageData(action) {
  console.log(action)
  try {
    yield put(setUploadImage({image:null, imgLoading: true}))
    const res = yield call(uploadCollectionImageAPI, action.payload);
    // console.log(res.data.message);
    yield put(setUploadImage({image:res?.data?.message, imgLoading: false}))
  } catch (error) {
    yield put(setUploadImage({image:null, imgLoading: false}))
  }
}

export function* sendCollectionDetails(action) {
  try {
    yield put(createCollectionStatus({ isLoading: true, status: null }));
    yield call(CollectionApi, action.payload);
    yield put(createCollectionStatus({ isLoading: false, status: 'success' }));
    yield call(getCollectionTableData);
  } catch (error) {
    // console.log(error.response.data.error)
    if(error.response.data.error === 'Collection Name Alaready Exist'){
      yield put(createCollectionStatus({ isLoading: false, status: 'failed', message: error.response.data.error }));
      return
    }
    yield put(createCollectionStatus({ isLoading: false, status: 'failed', message: '' }));

  }
}

export function* sendUpdateCollectionDara(action) {
  try {
    yield put(createCollectionStatus({ isLoading: true, status: null }));
    yield call(updateCollectionDetails, action.payload);
    yield put(createCollectionStatus({ isLoading: false, status: 'success' }));
    yield call(getCollectionTableData);
  } catch (error) {
    yield put(createCollectionStatus({ isLoading: false, status: 'failed' }));
  }
}


export function*updateCollecitonVisibleDetails(action){
  try {
    yield put(setState({error: null, isLoading: true}))
    yield call(updateVisibleCollectionApi, action.payload)
    yield put(setState({error: null, isLoading: false, status: 'success'}))
    yield call(getCollectionTableData);
    yield put(setState({error: null, isLoading: false, status: null}))
  } catch (error) {
    yield put(setState({error: error.response.data.error, isLoading: false, status: 'failed'}))
    yield put(setState({error: null, isLoading: false, status: null}))
  }
}

export function* watchGetCollections() {
  yield takeEvery(GET_COLLECTIONS, getCollectionTableData);
}

export function* watchuploadImageData() {
  yield takeEvery(UPLOAD_IMAGE, uploadImageData);
}
export function* watchcreateCollection() {
  yield takeEvery(CREATE_COLLECTION, sendCollectionDetails);
}
export function* watchsendUpdateCollectionDara() {
  yield takeEvery(UPDATE_COLLECTION, sendUpdateCollectionDara);
}
export function* watchupdateCollecitonVisibleDetails() {
  yield takeEvery(UPDATE_COLLECTIONS_VISIBLE, updateCollecitonVisibleDetails);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCollections),
    fork(watchuploadImageData),
    fork(watchcreateCollection),
    fork(watchsendUpdateCollectionDara),
    fork(watchupdateCollecitonVisibleDetails),
  ]);
}
