import axios from 'axios';
const Apibaseurl = require('../../constants/apibaseurl');
var apibaseurl = Apibaseurl.getAPiUrl();

export const getPremintAssest = (id) => {
  const config = {
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
  return axios.get(`${apibaseurl}/api/admin/allPremintAsset/${id}`, config);
};

export const getPremintAssestCount = () => {
  //   const assestData = [];
  const config = {
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
  return axios.get(`${apibaseurl}/api/admin/countAllAsset`, config);
};

export const createPremintAssest = (newjsondata) => {
  // console.log(newjsondata)
  const config = {
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };

  return axios.post(`${apibaseurl}/api/admin/preMint/uri`, newjsondata, config);
};

export const updatePremintAssest = (newjsondata) => {
  // console.log(newjsondata)
  const config = {
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };

  return axios.post(
    `${apibaseurl}/api/admin/updatePremintAsset`,
    newjsondata,
    config
  );
};

export const updateShowHidePremintAssetAPI = (data) => {
  const config = {
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
  return axios.post(`${apibaseurl}/api/admin/updateShowItem`, data, config);
};

export const uploadPremintAssestImageAPI = (data) => {
  console.log(data)
  const config = {
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  };
  return axios.post(`${apibaseurl}/api/admin/assetFileUpload`, data, config);

  // console.log(data);
  // const url = "https://api.pinata.cloud/pinning/pinFileToIPFS";

  // return axios.post(url, data, {
  //   maxContentLength: -1,
  //   headers: {
  //     'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
  //     pinata_api_key: '32d944bab133a65ecf62',
  //     pinata_secret_api_key:
  //       'a90d6a495308b636cb8a486b0f4354161640bed6ee530f45852e33b13c511a56',
  //     path: 'somename',
  //   },
  // });
  // console.log(response)
  // https://backend.daniclosandorra.thenftbrewery.com/api/admin/uploadCollectionImage
};
